import React from "react";
import {ApiLink} from "../components/link";
import {CodeBlend, CodeRef} from "../components/code";

const options = [
    {
        name: 'MARIADB.LIBS',
        scope: 'api',
        description: `Forces SQLAPI++ Library to use specified MariaDB client library.`,
        valid:
            <>
                Any valid MariaDB CLI library name list. Names separated by ';' on
                Windows or ':' on other operating systems.
            </>,
        defaultValue:
            <ul>
                <li>Windows - {' '}<code>"libmariadb.dll"</code></li>
                <li>Linux
                    - {' '}<code>"libmariadb.so:libmariadb.so.3"</code>
                </li>
            </ul>,
        special:
            <>
                <code>"STATIC"</code>, forces using the linked MariaDB client API functions when
                the library is compiled with {' '}<code className="text-reset">SA_STATIC_MARIADB</code>{' '} build option.
            </>
    },
    {
        name: 'SkipServerInit',
        scope: 'api',
        description:
            <>
                Forces SQLAPI++ Library to not call
                {' '}<CodeBlend>mysql_server_init()</CodeBlend>{' '} API function when MariaDB client
                library is loaded. It must then be called explicitly with desired user specific options.
                Useful for embedded MariaDB library.
            </>,
        valid:
            <>
                <code>"True"</code>, <code>"1"</code>
            </>,
        defaultValue: <code>"False"</code>
    },
    {
        name: `
CLIENT_COMPRESS,
CLIENT_FOUND_ROWS,
CLIENT_IGNORE_SPACE,
CLIENT_INTERACTIVE,
CLIENT_LOCAL_FILES,
CLIENT_NO_SCHEMA,
CLIENT_ODBC,
CLIENT_MULTI_STATEMENTS,
CLIENT_MULTI_RESULTS,
CLIENT_REMEMBER_OPTIONS
`.trim(),
        scope: 'connection',
        description:
            <>
                Allows to specify various options that will be passed on
                to {' '}<CodeBlend>mysql_real_connect()</CodeBlend>.
                See MariaDB documentation for more information how these options affect underlying
                connection behaviour.
            </>,
        valid:
            <>
                <code>"true"</code>{' '} to specify a flag,
                {' '}<code>"false"</code> (or not specified) to omit
            </>,
        defaultValue:
            <>
                not specified, but {' '}<CodeBlend>CLIENT_MULTI_STATEMENTS</CodeBlend>,
                {' '}<CodeBlend>CLIENT_MULTI_RESULTS</CodeBlend>{' '}
                and CLIENT_REMEMBER_OPTIONS are turned on
            </>
    },
    {
        name: 'CharacterSet',
        scope: 'connection',
        description: `Allows to set the client connection character set.`,
        valid:
            <>
                Any MariaDB allowed character set name
            </>,
        defaultValue:
            <>
                not specified
            </>
    },    
    {
        name: 'Utf8CharacterSetName',
        scope: 'connection',
        description:
            <>
                Allows to define the client connection character set used by Unicode SQLAPI++ version.
            </>,
        valid:
            <>
                Any MariaDB allowed Unicode character set name
            </>,
        defaultValue:
            <>
                <code>"utf8"</code>
            </>
    },
    {
        name: 'UseMySQLKillQueryConnection',
        scope: 'connection',
        description:
            <>
                Instructs SQLAPI++ to create an additional connection that is used for
                {' '}<CodeBlend>'KILL QUERY ..'</CodeBlend>{' '} command
                when <ApiLink>SACommand::Cancel</ApiLink> method executed.
            </>,
        valid:
            <>
                <code>"true"</code>, <code>"false"</code>
            </>,
        defaultValue: <code>"false"</code>
    },
    {
        name: `
MYSQL_OPT_CONNECT_TIMEOUT,
MYSQL_OPT_READ_TIMEOUT,
MYSQL_OPT_WRITE_TIMEOUT
`.trim(),
        scope: 'connection',
        description:
            <>
                Allows to specify timeout values that are used with {' '}<CodeBlend>mysql_options</CodeBlend>.
                See MariaDB documentation for more information how these options affect underlying connection behaviour.
            </>,
        valid:
            <>
                string that represents an integer value
            </>,
        defaultValue:
            <>
                not specified
            </>
    },
    {
        name: 'MYSQL_OPT_RECONNECT',
        scope: 'connection',
        description:
            <>
                Allows to specify reconnect option that will be used with {' '}<CodeBlend>mysql_options</CodeBlend>.
                See MariaDB documentation for more information on how this option affects underlying connection behaviour.
            </>,
        valid:
            <>
                <code>"true"</code>{' '} to specify a flag, <code>"false"</code> to omit
            </>,
        defaultValue:
            <>
                not specified
            </>
    },
    {
        name: 'MYSQL_OPT_SSL_ENFORCE',
        scope: 'connection',
        description:
            <>
                This enables TLS with the default system settings. It does not prevent
                the connection from being created if the server does not support TLS.
                See MariaDB documentation for more information about these options.
            </>,
        valid:
            <>
                <code>"true"</code>{' '} to specify a flag, <code>"false"</code> to omit
            </>,
        defaultValue:
            <>
                not specified
            </>
    },
    {
        name: 'MYSQL_OPT_SSL_VERIFY_SERVER_CERT',
        scope: 'connection',
        description:
            <>
                Enables (or disables) server certificate verification.
                See MariaDB documentation for more information about these options.
            </>,
        valid:
            <>
                <code>"true"</code>{' '} to specify a flag, <code>"false"</code> to omit
            </>,
        defaultValue:
            <>
                not specified
            </>
    },
    {
        name: 'MARIADB_OPT_TLS_VERSION',
        scope: 'connection',
        description:
            <>
                Allows to specify MariaDB SSL protocols the client permits for encrypted connections.
                See MariaDB documentation for more information about these options.
            </>,
        defaultValue:
            <>
                not specified
            </>
    },
    {
        name: 'MYSQL_SSL_KEY, MYSQL_SSL_CERT, MYSQL_SSL_CA, MYSQL_SSL_CAPATH, MYSQL_SSL_CIPHER',
        scope: 'connection',
        description:
            <>
                Allows to specify MariaDB SSL parameters that will be used with {' '}<CodeBlend>mysql_ssl_set</CodeBlend>.
                MariaDB API method called only when at least one mentioned or <code>MYSQL_OPT_SSL_MODE</code>
                parameter specified. See MariaDB documentation for more information about these options.
            </>,
        defaultValue:
            <>
                not specified
            </>
    },
    {
        name: 'HandleResult',
        scope: 'command',
        description:
            <>
                Determines whether the result set will be stored in a temporary table or a local buffer
                on a client (it allows to open several result sets simultaneously in one transaction),
                or it will be read row by row directly from the server (somewhat faster and uses much
                less memory). For more information see MariaDB documentation on
                {' '}<CodeBlend>mysql_store_result()</CodeBlend>{' '}
                and {' '}<CodeBlend>mysql_use_result()</CodeBlend>{' '} functions.
            </>,
        valid:
            <ul>
                <li>
                    <code>"use"</code>{' '} to force SQLAPI++ to
                    call {' '}<CodeBlend>mysql_use_result()</CodeBlend>{' '} function
                </li>
                <li>
                    <code>"store"</code> to force SQLAPI++ to call <CodeBlend>mysql_store_result()</CodeBlend> function
                </li>
            </ul>,
        defaultValue: <code>"use"</code>
    },
    {
        name: 'UseStatement',
        scope: 'command',
        description:
            <>
                Determines whether MariaDB statement API should be used (if supported by MariaDB client
                library). Be aware that statement API cannot be used with multi-statement,
                multi-result queries and has some additional restrictions.
            </>,
        valid:
            <>
                <code>"true"</code>, <code>"false"</code>
            </>,
        defaultValue: <code>"false"</code>
    },
    {
        name: 'PreFetchRows',
        scope: 'command',
        description:
            <>
                Forces SQLAPI++ library to fetch rows in bulk, rather than retrieving records one by one.
                Only has an effect if MariaDB statement API is used
                (see {' '}<CodeRef>UseStatement</CodeRef>{' '} option).
            </>,
        valid: 'String containing number of rows in the fetch buffer',
        defaultValue: <code>"1"</code>
    },
    {
        name: 'UseDynamicCursor, Scrollable',
        scope: 'command',
        description: `Forces SQLAPI++ to store MariaDB result set that allows scrolling through the data rows.`,
        valid:
            <>
                <code>"True"</code>, {' '}<code>"1"</code>
            </>,
        defaultValue: <code>"False"</code>
    },
    {
        name: 'IgnoreFieldBinaryFlag',
        scope: 'command',
        description: `Forces SQLAPI++ to ignore MariaDB result set field binary flag - any STRING/BLOB field is interpreted as a text data when this option is used.`,
        valid:
            <>
                <code>"True"</code>, {' '}<code>"1"</code>
            </>,
        defaultValue: <code>"False"</code>
    }
];

export {
    options
};
