import React from "react";

import ServerGuide, {
    GeneralUsingNativeApiNotes,
    GeneralNativeConnectionHandles,
    GeneralNativeCommandHandles,
    GenericPosNotSupportedErrorNotes
} from "../../components/server-guide";
import {options} from "../../data/mariadb";
import {Callout} from "../../components/alert";
import {ApiLink} from "../../components/link";

const ConnectApiNotes = () => (
    <Callout heading="Minimum Version">
        SQLAPI++ library requires MariaDB C API version 5.5 or higher.
    </Callout>
);
const sDBString = () => (
    <>
        <p>
            Connection string in the following format:
        </p>
        <ul>
            <li>
                <code>""</code>{' '} or {' '}<code>"@"</code>{' '} - empty string or '@' character, connects to a local
                server
            </li>
            <li>
                <code>{`<database_name>`}</code>{' '} or {' '}<code>{`@<database_name>`}</code>{' '} - connects to a
                database with the specified name on local server
            </li>
            <li>
                <code>{`<serve_name>@`}</code>{' '} - connects to the specified server
            </li>
            <li>
                <code>{`<server_name>@<database_name>`}</code>{' '} - connects to a database with the specified name
                on the specified server
            </li>
        </ul>
        <p>
            <code>{`<server_name>`}</code>{' '} can have the following formats:
        </p>
        <ul>
            <li>hostname[,port]</li>
            <li>pathname of the Unix socket that is used to connect to the server</li>
        </ul>
    </>
);

const UserID = () => (<p>A string containing a user name to use when establishing the connection.</p>);

const OutputParamNotes = () => (
    <p>
        MariaDB stored procedures can have output parameters.
        SQLAPI++ gets the output parameters from the server after all result sets from stored procedure (if any)
        are completely processed using <ApiLink>SACommand::FetchNext</ApiLink> method.
    </p>
);

const CancelCommandNotes = ({optionsLink}) => (
    <>
        <p>
            For modern MariaDB versions SQLAPI++ executes {' '}<code>'KILL QUERY ...'</code>{' '} command;
            for older versions it calls {' '}<code>mysql_kill</code>{' '} function to cancel a query, and then calls
            {' '}<code>mysql_ping</code>{' '} function to restore connection with server.
        </p>
        <p>
            Be aware that usually {' '}<code>'KILL QUERY ...'</code>{' '}
            or {' '}<code>mysql_kill</code>{' '} should be executed on a different connection that have permissions to
            cancel other commands. This additional connection can be automatically created
            if {' '}<code>"UseMySQLKillQueryConnection"</code>{' '} {' '}<a href={optionsLink}>option</a>{' '}
            is set to {' '}<code>"true"</code>.
        </p>
        <p>
            To get more details see 'KILL QUERY ...', **mysql_kill** and **mysql_ping** functions
            description in **MySQL** documentation.
        </p>
    </>
);

const Page = () => (
    <ServerGuide server="MariaDB"
                 connectApiNotes={ConnectApiNotes}
                 sDBString={sDBString}
                 sUserID={UserID}
                 outputParamNotes={OutputParamNotes}
                 cancelCommandNotes={CancelCommandNotes}
                 options={options}
                 usingNativeApiNotes={() => <GeneralUsingNativeApiNotes server="MariaDB"
                                                                        cppHeader="mdbAPI.h"
                                                                        apiClass="mdbAPI"/>}
                 nativeConnectionHandles={() => <GeneralNativeConnectionHandles server="MariaDB"
                                                                                handleClass="mdbConnectionHandles"
                                                                                cppHeader="mdbAPI.h"/>}
                 nativeCommandHandles={() => <GeneralNativeCommandHandles server="MariaDB"
                                                                          handleClass="mdbCommandHandles"
                                                                          cppHeader="mdbAPI.h"/>}
                 errorNotes={() => <GenericPosNotSupportedErrorNotes server="MariaDB"/>}
    />
);

export default Page;
